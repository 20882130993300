
import richtext from '~/utils/richtext';
import cmsComponent from 'mixins/cmsComponent';

export default {
  name: 'cms-richtext',
  mixins: [cmsComponent],
  computed: {
    content() {
      if (typeof this.blok.content !== 'undefined') {
        return richtext(this.blok.content);
      }
      return '';
    },
  },
};
