
import GContainer from './goose/g-container';
import MarketoForm from './marketo-form';
import cmsComponent from 'mixins/cmsComponent';

export default {
  name: 'cms-marketo-form',
  components: {
    GContainer,
    MarketoForm,
  },
  mixins: [cmsComponent],
  beforeMount() {
    // load marketo script on page load
    this.$store.commit('setLoadAssets', 'munchkin,marketo');
  },
};
