
import GQuoteWithImage from './goose/g-quote-with-image';
import cmsComponent from 'mixins/cmsComponent';
import richtext from '~/utils/richtext';

export default {
  name: 'cms-quote-with-image',
  components: {
    GQuoteWithImage,
  },
  mixins: [cmsComponent],
  data() {
    return {
      quote: this.blok.quote.content,
      quoteContent: richtext(this.blok.quote.content.quote, true, true),
    };
  },
};
