
import GSpacer from './goose/g-spacer';
import GFullWidthQuote from './goose/g-full-width-quote';
import cmsComponent from 'mixins/cmsComponent';
import richtext from '~/utils/richtext';

export default {
  name: 'cms-full-width-quote',
  components: {
    GSpacer,
    GFullWidthQuote,
  },
  mixins: [cmsComponent],
  data() {
    return {
      quote: this.blok.quote.content,
      quoteContent: `"${richtext(this.blok.quote.content.quote, true, true)}"`,
      quotePosition: this.blok.quote_position,
    };
  },
};
