
import GInfographic from './goose/g-infographic';
import cmsComponent from 'mixins/cmsComponent';
export default {
  name: 'cms-infographic',
  components: {
    GInfographic,
  },
  mixins: [cmsComponent],
  computed: {
    full_slug() {
      return {
        full_slug: this.slug,
      };
    },
  },
};
