
import GIllustrationCopyLink from './goose/g-illustration-copy-link';
import cmsComponent from 'mixins/cmsComponent';
import parseLinkHref from '~/utils/parseLinkHref';
import richtext from '~/utils/richtext';

export default {
  name: 'cms-illustration-copy-link',
  components: {
    GIllustrationCopyLink,
  },
  mixins: [cmsComponent],
  computed: {
    illustrationCopyLinkProps() {
      return {
        title: richtext(this.blok.title, true),
        image: this.blok.image,
        animationUrl: this.blok.lottie_animation.filename || '',
        buttonText: this.blok.button_text,
        buttonHref: this.getCtaHref(this.blok.button_link),
      };
    },
  },
  methods: {
    getCtaHref(linkObject) {
      return linkObject.cached_url !== '' ? parseLinkHref(linkObject) : '';
    },
  },
};
