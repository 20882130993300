
import GTabCopyMedia from './goose/g-tab-copy-media';
import GHeader from './goose/g-header';
import GContainer from './goose/g-container';
import cmsComponent from 'mixins/cmsComponent';
import richtext from '~/utils/richtext';

export default {
  name: 'cms-tab-copy-media',
  components: {
    GTabCopyMedia,
    GContainer,
    GHeader,
  },
  mixins: [cmsComponent],
  computed: {
    tabs() {
      const tabsName = [];
      const tabsContent = [];
      this.blok.tabs.map(tab => {
        tabsName.push(tab.tab_name);
        tabsContent.push({
          headline: richtext(tab.headline, true),
          paragraph: richtext(tab.paragraph, true),
          image: tab.image,
          media_orientation: tab.media_orientation,
          animation_url: tab.animation.filename,
          animation_placeholder: tab.animation_placeholder,
          video: tab.video,
          selected_at_load: tab.selected_at_load,
          key: tab._uid,
        });
      });
      return {
        tabsName: tabsName,
        tabsContent: tabsContent,
      };
    },
    headerTitle() {
      if (typeof this.blok.header[0].text !== 'undefined') {
        if (
          typeof this.blok.header[0].text !== 'undefined' &&
          typeof this.blok.header[0].text !== 'string'
        ) {
          return richtext(this.blok.header[0].text, true, true);
        } else {
          return this.blok.header[0].text;
        }
      }
      return undefined;
    },
  },
};
