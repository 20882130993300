
import GLightBgTestimonial from './goose/g-light-bg-testimonial';
import cmsComponent from 'mixins/cmsComponent';
import parseLinkHref from '~/utils/parseLinkHref';
import richtext from '~/utils/richtext';

export default {
  name: 'cms-light-bg-testimonial',
  components: {
    GLightBgTestimonial,
  },
  mixins: [cmsComponent],
  computed: {
    lightBgTestimonialProps() {
      return {
        quote: richtext(this.blok.quote, true),
        text: richtext(this.blok.text, true),
        image: this.blok.image,
        author: this.blok.author,
        authorTitle: this.blok.author_title,
        buttonText: this.blok.button_text,
        buttonLink: parseLinkHref(this.blok.button_link),
        videoThumbnail: this.blok.video_thumbnail,
        videoEmbedUrl: this.blok.video_embed_url,
        poscastButtonText: this.blok.poscast_button_text,
        poscastButtonLink: parseLinkHref(this.blok.poscast_button_link),
        signupCtaId: 'light-bg-testimonial',
      };
    },
  },
};
