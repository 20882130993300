
import { mapState } from 'vuex';
import cmsComponent from 'mixins/cmsComponent';
import gStateList from './goose/g-state-list';

export default {
  name: 'cms-state-list',
  components: { gStateList },
  mixins: [cmsComponent],
  computed: {
    ...mapState({
      complianceStates: state => state.complianceStates,
    }),
  },
};
