
import GContainer from '../goose/g-container';
import GLazyImage from '../goose/g-lazy-image';

export default {
  name: 'cro-icon-trio',
  components: {
    GContainer,
    GLazyImage,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
};
