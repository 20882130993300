
import cmsComponent from 'mixins/cmsComponent';
import GHeadlineDivider from './goose/g-headline-divider';
import GWorkplaceLogos from './goose/g-workplace-logos';

export default {
  name: 'cms-workplaces-logo-block',
  components: {
    GHeadlineDivider,
    GWorkplaceLogos,
  },
  mixins: [cmsComponent],
  computed: {
    headline() {
      return this.interpolateStats(this.blok.headline);
    },
    getWorkplaces() {
      return this.blok.workplaces.length > 0
        ? this.blok.workplaces
        : this.blok.integrations;
    },
    getLogosAlignment() {
      return this.blok.logos_alignment || 'center';
    },
  },
  methods: {
    colLayout(layout, fallback = null) {
      if (this.blok.hasOwnProperty(layout)) {
        return parseInt(this.blok[layout]);
      } else {
        return fallback ?? this.getWorkplaces.length;
      }
    },
  },
};
