
import GCardsOutlineHover from './goose/g-cards-outline-hover';
import cmsComponent from 'mixins/cmsComponent';
import parseLinkHref from '~/utils/parseLinkHref';
export default {
  name: 'cms-related-features',
  components: {
    GCardsOutlineHover,
  },
  mixins: [cmsComponent],
  computed: {
    cards() {
      return this.blok.related_features.map(feature => {
        return {
          url: parseLinkHref(feature.content.url),
          title: feature.content.title,
          paragraph: feature.content.paragraph,
          icon: feature.content.icon,
        };
      });
    },
  },
};
