
import GLazyImage from './goose/g-lazy-image';
import cmsComponent from 'mixins/cmsComponent';

export default {
  name: 'cms-lazy-image',
  components: {
    GLazyImage,
  },
  mixins: [cmsComponent],
  computed: {
    bindProps() {
      return {
        imageData: this.blok.image,
        imgSizeSm: parseInt(this.blok.img_size_sm),
        imgSizeMd: parseInt(this.blok.img_size_md),
        imgSizeLg: parseInt(this.blok.img_size_lg),
        imgSizeXl: parseInt(this.blok.img_size_xl),
      };
    },
  },
};
