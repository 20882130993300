
import GSpacer from './goose/g-spacer';
import GSectionDarkOverhang from './goose/g-section-dark-overhang';
import GHeader from './goose/g-header';
import cmsComponent from 'mixins/cmsComponent';
import richtext from '~/utils/richtext';
import GMedia from '~/components/goose/g-media/g-media.vue';

export default {
  name: 'cms-header-dark-overhang',
  components: {
    GMedia,
    GSectionDarkOverhang,
    GSpacer,
    GHeader,
  },
  mixins: [cmsComponent],
  computed: {
    mediaProps() {
      return {
        image: this.blok.image,
        loadImmediately: true,
        animationUrl: this.blok.lottie[0]?.animation?.filename,
        mobileAnimationUrl: this.blok.lottie[0]?.mobileAnimation?.filename,
        placeholderImage: this.blok.lottie[0]?.placeholder,
        videoEmbedUrl: this.blok.video[0]?.videoEmbedUrl,
        videoThumbnail: this.blok.video[0]?.videoThumbnail,
        playVideoOption: this.blok.video[0]?.playVideoOption,
        imageSizeSm: 366,
        imageSizeMd: 719,
        imageSizeLg: 927,
        imageSizeXl: 1216,
      };
    },
    full_slug() {
      return {
        full_slug: this.slug,
      };
    },
    headerTitle() {
      if (typeof this.blok.intro[0].text !== 'undefined') {
        if (
          typeof this.blok.intro[0].text !== 'undefined' &&
          typeof this.blok.intro[0].text !== 'string'
        ) {
          return richtext(this.blok.intro[0].text, true, true);
        } else {
          return this.blok.intro[0].text;
        }
      }
      return undefined;
    },
    headerSubline() {
      if (typeof this.blok.intro[0].subline !== 'undefined') {
        if (
          typeof this.blok.intro[0].subline !== 'undefined' &&
          typeof this.blok.intro[0].subline !== 'string'
        ) {
          return richtext(this.blok.intro[0].subline, true, true);
        } else {
          return this.blok.intro[0].subline;
        }
      }
      return undefined;
    },
  },
};
