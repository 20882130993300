// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/svg/squiggle/shape-curl-pink-40.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/svg/squiggle/shape-02-purple-30.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cms-marketo-form{background-color:#fff6f6;border-radius:1.25rem;margin:0 auto;max-width:33.1875rem;padding:3.125rem;position:relative}.cms-marketo-form .mktoForm{margin:0 auto}.cms-marketo-form .type-h4{margin-bottom:1.875rem;text-align:left}.cms-marketo-form:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");content:\"\";height:2.5625rem;left:4.375rem;position:absolute;top:-1.25rem;width:5.3125rem}.cms-marketo-form:after{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");bottom:-2.5rem;content:\"\";height:9.0625rem;position:absolute;right:-2.5rem;width:9rem;z-index:-1}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
