
import GFaqCollection from './goose/g-faq-collection';
import cmsComponent from 'mixins/cmsComponent';

export default {
  name: 'cms-faq-collection',
  components: {
    GFaqCollection,
  },
  mixins: [cmsComponent],
  data() {
    return {
      headlineFAQ: 'Frequently asked questions',
    };
  },
  created() {
    if (typeof this.blok.headline !== 'undefined') {
      this.headlineFAQ = this.blok.headline;
    }
  },
};
