
import GResourceCards from './goose/g-resource-cards';
import cmsComponent from 'mixins/cmsComponent';
import parseUrlRegion from '~/utils/parseUrlRegion';
import richtext from '~/utils/richtext';
import parseLinkHref from '~/utils/parseLinkHref';

export default {
  name: 'cms-resource-cards',
  components: {
    GResourceCards,
  },
  mixins: [cmsComponent],
  computed: {
    mappedCards() {
      return this.blok.resource_cards.map(card => {
        if (card.full_slug.includes('customers/')) {
          return {
            _uid: card.uuid,
            title: this.checkContentValue(card.content.headline),
            image: this.getCustomerImage(card.content),
            tag: 'customer story',
            linkUrl: this.linkHref(card.full_slug),
            ctaText: this.ctaText(card.content.custom_cta),
          };
        }
        if (card.full_slug.includes('blog/')) {
          return {
            _uid: card.uuid,
            title: card.content.headline,
            image: card.content.thumbnail,
            tag: this.createTag(card.full_slug),
            linkUrl: this.linkHref(card.full_slug),
            ctaText: this.ctaText(card.content.custom_cta),
          };
        } else {
          return {
            _uid: card.uuid,
            title: card.content.title,
            image: card.content.image,
            tag: card.content.tag,
            linkUrl: parseLinkHref(card.content.url),
            ctaText: this.ctaText(card.content.custom_cta),
          };
        }
      });
    },
  },
  methods: {
    linkHref(cardUrl) {
      if (cardUrl) {
        return parseUrlRegion(cardUrl);
      }
    },
    ctaText(customCta) {
      return customCta || 'Read more';
    },
    createTag(slug) {
      const regexp = /(customers|blog|_content_types)/;
      return slug.match(regexp)[0];
    },
    checkContentValue(content) {
      if (typeof content !== 'string') {
        return richtext(content, true);
      }
      return content;
    },
    getCustomerImage(content) {
      return content.story_image.imagePublicUrl
        ? content.story_image
        : content.logo;
    },
  },
};
