
import GStepByStep2Col from './goose/g-step-by-step-2-col';
import cmsComponent from 'mixins/cmsComponent';
import richtext from '~/utils/richtext';

export default {
  name: 'cms-step-by-step-2-col',
  components: {
    GStepByStep2Col,
  },
  mixins: [cmsComponent],
  computed: {
    properties() {
      return {
        heading: this.blok.heading,
        subheading: richtext(this.blok.paragraph, true),
        steps: this.blok.steps.map(step => richtext(step.content, true)),
        termsContent: richtext(this.blok.terms, true),
      };
    },
  },
};
