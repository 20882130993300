
import GContainer from './goose/g-container';
import GSpacer from './goose/g-spacer';
import GResourceCards from './goose/g-resource-cards';
import mapArticlesToCards from 'mixins/mapArticlesToCards';

export default {
  name: 'cms-related-articles',
  components: {
    GContainer,
    GSpacer,
    GResourceCards,
  },
  mixins: [mapArticlesToCards],
  props: {
    articles: {
      type: Array,
      required: true,
    },
  },
};
