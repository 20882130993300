
import cmsComponent from 'mixins/cmsComponent';
import GMedia from '~/components/goose/g-media/g-media.vue';

export default {
  name: 'cms-full-width-media',
  components: {
    GMedia,
  },
  mixins: [cmsComponent],
  computed: {
    mediaProps() {
      return {
        image: this.blok.image,
        loadImmediately: true,
        animationUrl: this.blok.lottie[0]?.animation?.filename,
        mobileAnimationUrl: this.blok.lottie[0]?.mobileAnimation?.filename,
        placeholderImage: this.blok.lottie[0]?.placeholder,
        videoEmbedUrl: this.blok.video[0]?.videoEmbedUrl,
        videoThumbnail: this.blok.video[0]?.videoThumbnail,
        playVideoOption: this.blok.video[0]?.playVideoOption,
        imageSizeSm: 414,
        imageSizeMd: 767,
        imageSizeLg: 991,
        imageSizeXl: 3000,
        imageSizeXxl: 5000,
      };
    },
  },
};
