
import GLazyImage from './goose/g-lazy-image';
import cmsComponent from 'mixins/cmsComponent';

export default {
  name: 'cms-full-width-image',
  components: {
    GLazyImage,
  },
  mixins: [cmsComponent],
  computed: {
    brandLogoClass() {
      return this.blok.logo_alignment
        ? `spark spark--${this.blok.logo_alignment}`
        : '';
    },
  },
};
