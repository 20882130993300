
import GHeaderWithCarouselQuotes from './goose/g-header-with-carousel-quotes';
import cmsComponent from 'mixins/cmsComponent';
export default {
  name: 'cms-header-with-carousel-quotes',
  components: {
    GHeaderWithCarouselQuotes,
  },
  mixins: [cmsComponent],
};
