
import cmsComponent from 'mixins/cmsComponent';
import richtext from '../utils/richtext';
export default {
  name: 'cms-trust-radius-widget',
  mixins: [cmsComponent],
  components: {
    GLazyImage: () => import('./goose/g-lazy-image'),
    GContainer: () => import('./goose/g-container'),
    GSpacer: () => import('./goose/g-spacer'),
  },
  computed: {
    ctaText() {
      return richtext(this.blok.cta, true);
    },
  },
};
