
import cmsComponent from 'mixins/cmsComponent';
import scrollToAnchor from '~/utils/scrollToAnchor';
import GProductTour from './goose/g-product-tour';
import GFeatureBucket from './goose/g-feature-bucket';

export default {
  name: 'cms-product-tour',
  components: {
    GProductTour,
    GFeatureBucket,
  },
  mixins: [cmsComponent, scrollToAnchor],
  data() {
    return {
      offsetMobile: -185,
      offsetMobileGeo: -255,
    };
  },
};
