
import GPricingOnPage from './goose/g-pricing-on-page';
import cmsComponent from 'mixins/cmsComponent';
import richtext from '~/utils/richtext';
import displayCosts from 'mixins/displayCosts';

export default {
  name: 'cms-pricing-on-page',
  components: {
    GPricingOnPage,
  },
  mixins: [cmsComponent, displayCosts],
  computed: {
    pricingProps() {
      return {
        heading: richtext(this.blok.heading),
        secondHeading: this.getSecondHeading,
        pricingCountry: this.$store.state.pricingCountry,
        region: this.region,
        pricing: this.$store.state.costs,
        plan: this.blok.plan,
        colourOverlap: this.blok.colour_overlap,
        linkText: this.blok.link_text,
        displayableTerms: this.getDisplayableTerms(this.plan),
        taxTerms: this.findTaxTerm(this.plan),
      };
    },
    getSecondHeading() {
      if (typeof this.blok.second_heading !== 'undefined') {
        return richtext(this.blok.second_heading, true);
      }
      return '';
    },
  },
  mounted() {
    this.plan = this.blok.plan;
  },
};
