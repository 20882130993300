
import cmsComponent from 'mixins/cmsComponent';
import parseLinkHref from '~/utils/parseLinkHref';
import GCardOutlineRound from './goose/g-card-outline-round';
import GButtonV2 from './goose/g-button-v2';
import GContainer from './goose/g-container';
import GSpacer from './goose/g-spacer';

export default {
  name: 'cms-round-cards-links',
  components: {
    GCardOutlineRound,
    GButtonV2,
    GContainer,
    GSpacer,
  },
  mixins: [cmsComponent],
  methods: {
    parseLinkHref,
  },
};
