
import cmsComponent from 'mixins/cmsComponent';
import GHeaderSearchbox from './goose/g-header-searchbox';
import richtext from '~/utils/richtext';

export default {
  name: 'cms-header-searchbox',
  components: {
    GHeaderSearchbox,
  },
  mixins: [cmsComponent],
  computed: {
    headerSearchboxProps() {
      const header = this.blok.header?.[0];

      return {
        headerProps: header
          ? {
              header,
              title: this.richtextParser(header.text),
              subline: this.richtextParser(header.subline),
              slug: { full_slug: this.slug },
            }
          : null,
        description: this.blok.description,
      };
    },
  },
  methods: {
    richtextParser(value) {
      if (typeof value !== 'undefined') {
        if (typeof value === 'string') {
          return value;
        }
        return richtext(value, true, true);
      }
      return null;
    },
  },
};
