
import GLogoDivider from './goose/g-logo-divider';
import cmsComponent from 'mixins/cmsComponent';
export default {
  name: 'cms-logo-divider',
  components: {
    GLogoDivider,
  },
  mixins: [cmsComponent],
};
