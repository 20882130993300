
import G2ColsImgCopy from './goose/g-2-cols-img-copy';
import GContainer from './goose/g-container';
import GSpacer from './goose/g-spacer';
import cmsComponent from 'mixins/cmsComponent';
import richtext from '~/utils/richtext';
import parseLinkHref from '~/utils/parseLinkHref';

export default {
  name: 'cms-trust-with-numbers',
  components: {
    G2ColsImgCopy,
    GSpacer,
    GContainer,
  },
  mixins: [cmsComponent],
  computed: {
    header() {
      return {
        headline: this.blok.header[0].headline,
        tagline: this.blok.header[0].tagline,
        paragraph: richtext(this.blok.header[0].paragraph),
        image: this.blok.header[0].image,
        imageAlignment: this.blok.header[0].image_alignment,
        linkUrl: parseLinkHref(this.blok.header[0].link_url),
        linkText: this.blok.header[0].link_text,
        lightBackground: this.blok.lightBackground,
        arrowColor: this.blok.lightBackground ? 'blue' : 'white',
        buttonOutlineColor: this.blok.lightBackground
          ? 'blue-outline'
          : 'spark-outline',
      };
    },
  },
};
