
import GPricingCard from './goose/g-pricing-card';
import GPricingCardSmall from './goose/g-pricing-card/g-pricing-card-small';
import cmsComponent from 'mixins/cmsComponent';
import displayCosts from 'mixins/displayCosts';
export default {
  name: 'cms-pricing-features',
  components: {
    GPricingCard,
    GPricingCardSmall,
  },
  mixins: [cmsComponent, displayCosts],
  data() {
    return {
      planUUID: {},
      freemium: false,
      notFeatured: ['Scheduling', 'Time & Attendance'],
      isDesktop: true,
      mounted: false,
    };
  },
  computed: {
    getPlans() {
      let plans = this.blok.plan.map(p => {
        this.plan = p.content.cost.toLowerCase();
        this.annualPlan = p.content.annual_plan;
        this.planUUID[p.uuid] = p.content.name;
        return {
          price: this.price ? this.formatPrice(this.price) : null,
          price_info: this.getDisplayableTerms(this.plan),
          id: this.getId(this.getRegionalDetails(p).name),
          price_annually: this.getDisplayableAnnualTerms(this.plan),
          name: this.getRegionalDetails(p).name,
          image: p.content.image,
          lottie_animation: p.content.lottie_animation,
          description: this.getRegionalDetails(p).description,
          ctaTarget: p.content.ctaTarget,
          ctaText: p.content.ctaText,
        };
      });
      // we need to filter the plan in a specific order based on the device and if freemium
      plans = this.filterPlans(
        plans,
        this.freemium && this.isDesktop ? this.notFeatured : []
      );
      if (this.isDesktop) {
        // re-order the plans
        if (this.freemium) {
          this.reorderPlans(
            plans.featured,
            ['Starter', 'Premium', 'Enterprise'],
            [0, 1, 2]
          );
        } else {
          this.removeFreemiumPlan(plans.featured);
          this.reorderPlans(
            plans.featured,
            ['Scheduling', 'Time & Attendance', 'Premium', 'Enterprise'],
            [0, 1, 2, 3]
          );
        }
      } else {
        if (this.freemium) {
          this.reorderPlans(
            plans.featured,
            [
              'Premium',
              'Starter',
              'Scheduling',
              'Time & Attendance',
              'Enterprise',
            ],
            [0, 1, 2, 3, 4]
          );
        } else {
          this.removeFreemiumPlan(plans.featured);
          this.reorderPlans(
            plans.featured,
            ['Premium', 'Scheduling', 'Time & Attendance', 'Enterprise'],
            [0, 1, 2, 3]
          );
        }
      }
      return {
        featured: plans.featured,
        notFeatured: plans.notFeatured,
      };
    },
    getFeatures() {
      return this.blok.features.reduce((features, feature) => {
        // return only the features present in the country
        if (feature.content.region.includes(this.getCountry())) {
          features.push({
            name: this.getRegionalDetails(feature).name,
            plan: this.getPlansName(feature.content.plan),
            description: this.getRegionalDetails(feature).description,
            hidden: this.getPlansName(feature.content.hidden),
          });
        }
        return features;
      }, []);
    },
  },
  mounted() {
    if (typeof window !== 'undefined') {
      this.freemium =
        this.$store.state.costs.show_freemium.includes(this.country) &&
        this.$store.state.showFreemium
          ? this.blok.show_freemium
          : false;
      this.isDesktop = window.innerWidth >= 1200;
      this.mounted = true;
    }
  },
  methods: {
    getCountry() {
      return ['au', 'gb'].includes(this.country) ? this.country : 'us';
    },
    getId(name) {
      return `signup_${name.replace(' & ', '-and-').toLowerCase()}`;
    },
    filterPlans(array, toExtract) {
      const pricingFeatured = [];
      const pricingNotFeatured = [];
      array.map(arr => {
        if (toExtract.includes(arr.name)) {
          pricingNotFeatured.push(arr);
        } else {
          pricingFeatured.push(arr);
        }
      });
      return {
        featured: pricingFeatured,
        notFeatured: pricingNotFeatured,
      };
    },
    removeFreemiumPlan(array) {
      // we need to make sure we removed any freemium plan from the array
      // if not allowed for the user country
      const index = this.getItemIndex(array, 'Starter');
      if (index >= 0) {
        // remove the item from his position
        array.splice(index, 1);
      }
    },
    getItemIndex(array, value) {
      // get the index of the value that we want to move around
      return array.map(e => e.name).indexOf(value);
    },
    reorderPlans(array, value, moveAt) {
      value.forEach((val, i) => {
        const index = this.getItemIndex(array, val);
        // the item has been found
        if (index !== -1) {
          // get the item
          const item = array[index];
          // remove the item from his position
          array.splice(index, 1);
          // move the item at the desired position
          array.splice(moveAt[i], 0, item);
        }
      });
    },
    getPlansName(plans) {
      if (plans) {
        return plans.map(plan => {
          // I'm doing this here because of the child level in the Json tree that contain a content_type
          // and Storyblok not allowing to resolve relations at a second level
          // I stored the uuid of the resolve plan to be able to know it here
          return this.planUUID[plan];
        });
      }
      return [];
    },
    getRegionalDetails(data) {
      const region = this.country.replace('us', '').toUpperCase();
      return {
        name: data.content[`name${region}`] || data.content.name,
        description:
          data.content[`description${region}`] || data.content.description,
      };
    },
  },
};
