
import GTable from './goose/g-table';
import cmsComponent from 'mixins/cmsComponent';
export default {
  name: 'cms-table',
  components: {
    GTable,
  },
  mixins: [cmsComponent],
  computed: {
    tableProps() {
      return {
        tbody: this.blok.content.tbody,
        thead: this.blok.content.thead,
        slim: this.blok.slim,
        theme: this.blok.theme,
      };
    },
  },
};
