
import GFeatureHeader from './goose/g-feature-header';
import cmsComponent from 'mixins/cmsComponent';
export default {
  name: 'cms-feature-header',
  components: {
    GFeatureHeader,
  },
  mixins: [cmsComponent],
};
