
import GLottieAnimation from './goose/g-lottie-animation';
import GContainer from './goose/g-container';
import GSpacer from './goose/g-spacer';
import cmsComponent from 'mixins/cmsComponent';

export default {
  name: 'cms-lottie-animation',
  components: {
    GSpacer,
    GContainer,
    GLottieAnimation,
  },
  mixins: [cmsComponent],
};
