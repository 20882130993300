
import GContainer from '../goose/g-container';
import GSpacer from '../goose/g-spacer';
import modalControl from 'mixins/modalControl';
import GLazyImage from '../goose/g-lazy-image';

export default {
  name: 'cro-video-teaser',
  components: {
    GContainer,
    GLazyImage,
    GSpacer,
  },
  mixins: [modalControl],
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    iframeSrc() {
      return this.isShown ? this.blok.video_embed_url : '';
    },
    contentClasses() {
      return this.blok.show_title_on_mobile ? 'd-block' : 'd-none d-md-block';
    },
  },
};
