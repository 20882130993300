
import richtext from '~/utils/richtext';
import cmsComponent from 'mixins/cmsComponent';

export default {
  name: 'cms-signup-form',
  components: {
    GSignupForm: () => import('./goose/g-signup-form'),
    GStars: () => import('./goose/g-stars'),
  },
  mixins: [cmsComponent],
  computed: {
    capterraStars() {
      return Number(this.$store.state.stats.capterra_score);
    },
    capterraReviews() {
      return this.$store.state.stats.capterra_total_reviews;
    },
    parseData() {
      return {
        ctaText: this.blok.cta_text,
        formId: this.blok.form_id,
        showSocialButton: this.blok.show_social_button || false,
        inline: true,
      };
    },
  },
  methods: {
    richtext,
  },
};
