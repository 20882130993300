
import GFeatureHighlight from './goose/g-feature-highlight';
import cmsComponent from 'mixins/cmsComponent';
export default {
  name: 'cms-feature-highlight',
  components: {
    GFeatureHighlight,
  },
  mixins: [cmsComponent],
};
