
import GInsightsHeader from './goose/g-insights-header';
import cmsComponent from 'mixins/cmsComponent';
export default {
  name: 'cms-insights-header',
  components: {
    GInsightsHeader,
  },
  mixins: [cmsComponent],
};
