
import { mapState } from 'vuex';

export default {
  name: 'cms-personalisation',
  props: {
    blok: {
      required: true,
      type: Object,
    },
    slug: {
      type: String,
      required: true,
    },
    region: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      experimentId: this.blok?.optimizely_experiment_name,
      selectedComponents: this.blok?.variation_a ?? null,
      finishedSelection: false,
      cookieName: 'depPer',
      hasAddedSignupTrackingEvents: false,
      optimizelyAudiences: {
        personalisation_poc: 'us_clinics',
        per_hospo_au: 'au_hospo',
        per_retail_au: 'au_retail',
      },
    };
  },
  computed: {
    ...mapState({
      isCustomer: state => state.isCustomer,
      optimizelyDataRetrieved: state => state.optimizelyDataRetrieved,
      personalisationStatus: state => state.personalisationStatus,
    }),
  },
  mounted() {
    window.onNuxtReady(() => {
      if (!this.optimizelyDataRetrieved) {
        this.selectVariation();
        this.$store.commit('setOptimizelyData', true);
      }
    });
  },
  created() {
    // 1st Step
    this.checkForPersonalisation();
  },
  methods: {
    // 1st Step
    checkForPersonalisation() {
      if (!this.$isServer) {
        // force personalisation based on query param (eg: ?personalisation=per_hospo_au)
        // or force it based on a campaign query param (eg: ?stream=all_hc)
        if (this.shouldForcePersonalisation() || this.hasCampaignQueryParam()) {
          this.activatePersonalisation();
        } else {
          // test personalisation from the cookie
          if (this.personalisationStatus === null) {
            this.hasAnyPersonalisation()
              ? this.activatePersonalisation()
              : this.activateControl();
          } else {
            this.personalisationStatus?.useControl
              ? this.activateControl()
              : this.activatePersonalisation();
          }
        }
      }
    },
    activatePersonalisation() {
      if (this.experimentId === this.personalisationStatus.experimentId) {
        this.selectedComponents = this.blok?.variation_b ?? null;
      } else {
        this.selectedComponents = null;
      }
      this.finishedSelection = true;
    },
    activateControl() {
      const experimentIdToUseForControl =
        this.personalisationStatus?.experimentId || null;

      if (!experimentIdToUseForControl) {
        const experiments = {
          useControl: true,
          experimentId: this.experimentId,
        };
        this.$store.commit('setPersonalisationStatus', experiments);
        this.selectedComponents = this.blok?.variation_a ?? null;
      } else if (this.experimentId !== experimentIdToUseForControl) {
        this.selectedComponents = null;
      } else {
        this.selectedComponents = this.blok?.variation_a ?? null;
      }
      this.finishedSelection = true;
    },
    hasAnyPersonalisation() {
      const depPerObj = window.depPer || null; // {personalisation_poc: true, per_hospo_au: false};
      const experiments = {
        useControl: true,
        experimentId: this.experimentId,
      };

      if (!depPerObj) {
        this.$store.commit('setPersonalisationStatus', experiments);
        return false;
      }

      const experimentsIds = Object.keys(this.optimizelyAudiences);
      for (const experimentId of experimentsIds) {
        if (
          depPerObj.hasOwnProperty(experimentId) &&
          depPerObj[experimentId] === true
        ) {
          experiments.useControl = false;
          experiments.experimentId = experimentId;
          this.$store.commit('setPersonalisationStatus', experiments);
          return true;
        }
      }
      return false;
    },
    shouldForcePersonalisation() {
      const personalisationQueryParam = this.$route?.query?.personalisation;
      if (personalisationQueryParam) {
        const experimentsIds = Object.keys(this.optimizelyAudiences);
        for (const experimentId of experimentsIds) {
          if (personalisationQueryParam === experimentId) {
            const experiments = {
              useControl: false,
              experimentId: experimentId,
            };
            this.$store.commit('setPersonalisationStatus', experiments);
            return true;
          }
        }
      }
      return false;
    },
    hasCampaignQueryParam() {
      const campaignQueryParam = this.$route?.query?.stream;
      // check if query param matches the Hospo AU personalisation campaign value
      if (campaignQueryParam && campaignQueryParam === 'all_hc') {
        const experiments = {
          useControl: false,
          experimentId: 'per_hospo_au',
        };
        this.$store.commit('setPersonalisationStatus', experiments);
        return true;
      }
      return false;
    },
    setSegmentCompanyData(data) {
      const companyIndustries = data?.company?.category || null;
      if (companyIndustries) {
        window.analytics.identify({
          companyIndustries: companyIndustries,
        });
      }
      const companyCountry = data?.company?.geo?.countryCode || null;
      if (companyCountry) {
        window.analytics.identify({
          companyCountry: companyCountry,
        });
      }
      const companyTags = data?.company?.tags || null;
      if (companyTags) {
        window.analytics.identify({
          companyTags: companyIndustries,
        });
      }
    },
    setPersonalisationTrackingData() {
      // track personalisation experience with the data team
      // https://github.com/DeputyApp/data-eng-events-spec/blob/master/proto/data/analytics/context/entity/personalisation.proto
      if (
        !window.personalisation ||
        !window.personalisation.hasOwnProperty('description') ||
        window.personalisation.description !==
          this.personalisationStatus.experimentId
      ) {
        const user = window.analytics.user();
        const traits = user.traits();
        const segmentPersonalisationData = traits.hasOwnProperty(
          'personalisation'
        )
          ? traits.personalisation
          : null;
        if (segmentPersonalisationData) {
          window.personalisation = segmentPersonalisationData;
        } else {
          window.personalisation = {
            platform: 'Optimizely',
            description: this.personalisationStatus.experimentId,
          };
          window.analytics.identify({
            personalisation: window.personalisation,
          });
        }
      }
    },
    isCookieSet() {
      return document.cookie.includes(`${this.cookieName}=`);
    },
    isDeputyClient() {
      if (this.isCustomer === null) {
        const user = window.analytics.user();
        const traits = user.traits(); // window.analytics.identify({'email': 'test@deputy.com'});
        const isCustomer = traits.hasOwnProperty('email');
        this.$store.commit('setCustomer', isCustomer);
        return this.isCustomer;
      }
      return this.isCustomer;
    },
    setCookie(daysToExpire, cookieObj) {
      const cookieValue = JSON.stringify(cookieObj);
      const date = new Date();
      date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
      document.cookie = `${
        this.cookieName
      }=${cookieValue}; expires=${date.toGMTString()}; path=/`;
    },
    updateCookie(
      daysToExpire,
      value = 'true',
      experimentId = this.experimentId
    ) {
      let cookieObj = null;

      const currentCookie = this.getCookie(this.cookieName);
      if (currentCookie !== '') {
        try {
          cookieObj = JSON.parse(currentCookie);
        } catch (e) {
          console.error('JSON parse error');
          cookieObj = null;
        }
      }

      if (cookieObj) {
        cookieObj[experimentId] = value;
        this.setCookie(daysToExpire, cookieObj);
      }
    },
    getCookie(cookieName) {
      if (document.cookie.length > 0) {
        let start = document.cookie.indexOf(cookieName + '=');
        if (start !== -1) {
          start = start + cookieName.length + 1;
          let end = document.cookie.indexOf(';', start);
          if (end === -1) {
            end = document.cookie.length;
          }
          return unescape(document.cookie.substring(start, end));
        }
      }
      return '';
    },
    removeCookie() {
      document.cookie = `${this.cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    },
    addSignupEvent(segmentEvent, optimizelyEvent, variationName) {
      window.addEventListener(segmentEvent, e => {
        this.$optimizelyClient.track(optimizelyEvent, this.$optimizelyUserId, {
          variation_name: variationName,
        });
      });
    },
    setSignupTrackingEvents(variationName) {
      if (!this.hasAddedSignupTrackingEvents) {
        this.hasAddedSignupTrackingEvents = true;
        const segmentEvents = [
          'SIGNUP_STARTED',
          'SIGNUP_ATTEMPT',
          'SIGNUP_SUCCESS',
        ];
        segmentEvents.forEach(segmentEvent => {
          const optimizelyEvent = segmentEvent.toLowerCase();
          this.addSignupEvent(segmentEvent, optimizelyEvent, variationName);
        });
      }
    },
    selectVariation() {
      if (!this.$isServer) {
        this.$optimizelyClient.onReady().then(
          () => {
            const experimentAudience =
              this.optimizelyAudiences[this.personalisationStatus.experimentId];
            const attributes = {
              [experimentAudience]:
                this.personalisationStatus.useControl === false,
            };
            const variationName = this.$optimizelyClient.activate(
              this.personalisationStatus.experimentId,
              this.$optimizelyUserId,
              attributes
            );
            this.setSignupTrackingEvents(variationName);
            if (variationName === 'variation_A') {
              // already set to variation A
              // do some tracking
              this.$optimizelyClient.track('view', this.$optimizelyUserId, {
                variation_name: variationName,
              });
            } else if (variationName === 'variation_B') {
              // do some tracking
              this.$optimizelyClient.track('view', this.$optimizelyUserId, {
                variation_name: variationName,
              });

              this.setPersonalisationTrackingData();
            } else {
              // user is not entered into any variation
              // remain on variation A but no tracking
            }
            this.finishedSelection = true;
          },
          // request failed
          () => (this.finishedSelection = true)
        );
      }
    },
  },
};
