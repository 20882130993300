
import GMediaOnPage from './goose/g-media-on-page';
import cmsComponent from 'mixins/cmsComponent';
import richtext from '~/utils/richtext';

export default {
  name: 'cms-media-on-page',
  components: {
    GMediaOnPage,
  },
  mixins: [cmsComponent],
  computed: {
    formatHeading2() {
      if (typeof this.blok.heading_2 !== 'undefined') {
        return richtext(this.blok.heading_2, false, true);
      }
      return '';
    },
    mediaOnPageProps() {
      return {
        heading: this.blok.heading,
        heading2: this.formatHeading2,
        videoEmbedUrl: this.blok.video_embed_url,
        desktopVideoThumbnail: this.blok.desktop_video_thumbnail,
        mobileVideoThumbnail: this.blok.mobile_video_thumbnail,
        tag: this.blok.tag,
        theme: this.blok.theme,
      };
    },
  },
};
