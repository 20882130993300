
import GLazyImage from './goose/g-lazy-image';
import cmsComponent from 'mixins/cmsComponent';
export default {
  name: 'cms-full-width-mobile-image',
  components: {
    GLazyImage,
  },
  mixins: [cmsComponent],
};
