
import cmsComponent from '~/mixins/cmsComponent';
import GFullWidthCarousel from './goose/g-full-width-carousel';
import richtext from '~/utils/richtext';

export default {
  name: 'cms-full-width-carousel',
  components: { GFullWidthCarousel },
  mixins: [cmsComponent],
  computed: {
    fullWithCarouselProps() {
      const header = this.blok.header?.[0];

      return {
        theme: this.blok.theme,
        carousels: this.blok.carousels,
        headerProps: header
          ? {
              header,
              title: this.richtextParser(header.text),
              subline: this.richtextParser(header.subline),
              slug: { full_slug: this.slug },
            }
          : null,
      };
    },
  },
  methods: {
    richtextParser(value) {
      if (typeof value !== 'undefined') {
        if (typeof value === 'string') {
          return value;
        }
        return richtext(value, true, true);
      }
      return null;
    },
  },
};
