
import GTrustCarouselQuote from './goose/g-trust-carousel-quote';
import cmsComponent from 'mixins/cmsComponent';
import parseUrlRegion from '~/utils/parseUrlRegion';
import Intersect from '~/utils/intersect';

export default {
  name: 'cms-trust-carousel-quote',
  components: {
    GTrustCarouselQuote,
    Intersect,
  },
  mixins: [cmsComponent],
  data() {
    return {
      carouselShouldLoad: false,
    };
  },
  computed: {
    trustCarouselQuoteProps() {
      return {
        // refactor quotes and create an array of props we pass to
        // g-trust-carousel-quote component
        quotes: this.blok.quotes.map(quote => {
          return {
            image: quote.content.image,
            brandImage: quote.content.brand,
            testimonial: quote.content.testimonial,
            author: this.generateAuthor(quote.content),
            customerInfoUrl: this.modifySlug(
              quote.content.customer_info_url.cached_url
            ),
          };
        }),
        slug: {
          full_slug: this.slug,
        },
        region: this.region,
        backgroundColor: this.blok.background_colour || 'peach-10',

        // we are forcing these two fields to be 'null' at the moment as the asset used here as placeholder
        // got deleted and caused the pages to malfunction.
        // we will eventually go through each page that uses this component to update the asset, but as a
        // hot fix we're not displaying it for now
        // @see: https://deputy.atlassian.net/browse/WWW-254
        animationUrl: null,
        animationPlaceholder: null,
        quoteFont: this.blok.quote_font || 'text-20',
        buttonText: this.blok.button_text,
      };
    },
  },
  methods: {
    loadCarousel() {
      this.carouselShouldLoad = true;
    },
    modifySlug(link) {
      // because the customers can be accessed from any regions but are only created in one region in SB
      // we just make sure that the breadcrumb reflect that and is based on the region of the current page
      const regex = new RegExp(`^${this.region}/`);
      let slug = link;
      if (!regex.test(slug)) {
        slug = slug.replace(/^(au|gb|us)\//, `${this.region}/`);
      }
      return this.parseUrlRegion(slug);
    },
    generateAuthor(content) {
      let author = `${content.author}, `;
      author +=
        content.author_position !== '' ? `${content.author_position}, ` : '';
      author += content.company;
      return author;
    },
    parseUrlRegion,
  },
};
