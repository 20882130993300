
import GResourceCards from './goose/g-resource-cards';
import cmsComponent from 'mixins/cmsComponent';
import parseLinkHref from '~/utils/parseLinkHref';

export default {
  name: 'cms-resource-cards-editable',
  components: {
    GResourceCards,
  },
  mixins: [cmsComponent],
  computed: {
    serialisedResourceCards() {
      return this.blok.resource_cards.map(card => {
        return {
          _uid: card._uid,
          title: card.title,
          image: card.image,
          tag: card.tag,
          linkUrl: parseLinkHref(card.url),
          ctaText: this.ctaText(card.custom_cta),
        };
      });
    },
  },
  methods: {
    ctaText(customCta) {
      return customCta || 'Read more';
    },
  },
};
