
import GHeaderMediaCtas from './goose/g-header-media-ctas';
import GWaveContainer from './goose/g-wave-container';
import cmsComponent from 'mixins/cmsComponent';
import { convertStoryblokCTAs } from '~/utils/storyblokHelpers';

export default {
  name: 'cms-header-media-ctas',
  components: {
    GHeaderMediaCtas,
    GWaveContainer,
  },
  mixins: [cmsComponent],
  data() {
    return {
      urlParams: '',
    };
  },
  computed: {
    bindProps() {
      return convertStoryblokCTAs(
        this.blok,
        this.urlParams,
        'cms-header-media-ctas'
      );
    },
    paddingBottom() {
      if (this.blok.theme === 'white' || !this.paddingTop) {
        return '';
      }
      return this.paddingTop.map(val => {
        return val.replace('pt', 'pb');
      });
    },
  },
  mounted() {
    // we need to pass url params (includes userId) to typeform links be able to do proper tracking
    // in this section we search for userId in url params and if it includes userId,
    // we pass all params to the typeform link
    const params = window.location.search.toLowerCase();
    if (params.includes('userid')) {
      this.urlParams = params;
    }
  },
};
