
import cmsComponent from 'mixins/cmsComponent';
import GWorkplaceLogos from './goose/g-workplace-logos/g-workplace-logos';
import IconArrowCurve from 'assets/svg/icons/icon-arrow-curve.svg';

export default {
  name: 'cms-trust-with-workplaces',
  components: { GWorkplaceLogos, IconArrowCurve },
  mixins: [cmsComponent],
  computed: {
    hideWorkplaceLogo() {
      return this.blok.hide_workplaces;
    },
  },
};
