
import GNewsletter from './goose/g-newsletter';
import cmsComponent from 'mixins/cmsComponent';
export default {
  name: 'cms-newsletter-open-modal',
  components: {
    GNewsletter,
  },
  mixins: [cmsComponent],
  computed: {
    bindingProps() {
      return {
        headline: this.blok.headline,
        text: this.blok.subline,
        mini: this.blok.mini,
        ctaText: this.blok.ctaText,
      };
    },
  },
  mounted() {
    this.setNewsletterModalContent();
  },
  methods: {
    openModal() {
      this.$root.$emit('open-deputy-modal', {
        modalTarget: '#newsletterModal',
        id: 'newsletterModal',
        color: 'darkPurpleMedium',
      });
    },
    setNewsletterModalContent() {
      this.$store.commit('modal/SET_NEWSLETTER_CONTENT', {
        headline: this.blok.headline,
        subline: this.interpolateStats(this.blok.subline),
        ctaText: this.blok.ctaText,
        formID: this.blok.formID,
      });
    },
  },
};
