
import GTestimonialWithFaq from './goose/g-testimonial-with-faq';
import cmsComponent from 'mixins/cmsComponent';
import richtext from '~/utils/richtext';
export default {
  name: 'cms-testimonial-with-faq',
  components: {
    GTestimonialWithFaq,
  },
  mixins: [cmsComponent],
  computed: {
    parseContent() {
      return {
        faq: richtext(this.blok.faq, true),
        headline: this.blok.headline,
        subline: this.blok.subline,
        detail: this.blok.detail,
        image: this.blok.image,
      };
    },
  },
};
